var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "weather" }, [
    _c("div", { staticClass: "intro" }, [
      _c("p", { staticClass: "temp" }, [_vm._v(_vm._s(_vm.temp))]),
      _c("i", { class: `owi owi-${_vm.icon}` }),
    ]),
    _c("p", { staticClass: "description" }, [_vm._v(_vm._s(_vm.description))]),
    _vm.showDetails && _vm.weatherDetails.length > 0
      ? _c(
          "div",
          { staticClass: "details" },
          _vm._l(_vm.weatherDetails, function (section, indx) {
            return _c(
              "div",
              { key: indx, staticClass: "info-wrap" },
              _vm._l(section, function (weather) {
                return _c(
                  "p",
                  { key: weather.label, staticClass: "info-line" },
                  [
                    _c("span", { staticClass: "lbl" }, [
                      _vm._v(_vm._s(weather.label)),
                    ]),
                    _c("span", { staticClass: "val" }, [
                      _vm._v(_vm._s(weather.value)),
                    ]),
                  ]
                )
              }),
              0
            )
          }),
          0
        )
      : _vm._e(),
    _vm.weatherDetails.length > 0
      ? _c(
          "p",
          { staticClass: "more-details-btn", on: { click: _vm.toggleDetails } },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.showDetails
                    ? _vm.$t("widgets.general.show-less")
                    : _vm.$t("widgets.general.show-more")
                ) +
                " "
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }